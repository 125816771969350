import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { TweenOneGroup } from "rc-tween-one";
import type { FormInstance, InputRef } from "antd";
import { Input, Tag, theme, Form } from "antd";

type TagInputProps = {
  handleSelectedTag: (tags: string[]) => void;
  selectedTag: string[];
  form: FormInstance;
};

const TagInput = ({ handleSelectedTag, selectedTag, form }: TagInputProps) => {
  const { token } = theme.useToken();
  const [tags, setTags] = useState<Array<string>>(selectedTag);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    handleSelectedTag && handleSelectedTag(newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (
      inputValue.length > 0 &&
      inputValue &&
      tags &&
      tags.indexOf(inputValue) === -1
    ) {
      setTags([...tags, inputValue]);
      setInputValue("");
      handleSelectedTag && handleSelectedTag([...tags, inputValue]);
    }
    setInputVisible(false);
  };

  const forMap = (tag: string) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
        style={{
          fontSize: "13px",
          padding: "3px 5px",
          marginTop: "15px",
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
    width: "100%",
    padding: "15px 10px",
    marginTop: "15px",
  };

  return (
    <>
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{ marginTop: "15px", padding: "15px 10px" }}
          value={inputValue}
          maxLength={100}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag onClick={showInput} style={tagPlusStyle}>
          <PlusOutlined /> New Headline
        </Tag>
      )}
      <div style={{ marginBottom: 16 }}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: "from",
            duration: 100,
          }}
          onEnd={(e: any) => {
            if (e.type === "appear" || e.type === "enter") {
              (e.target as any).style = "display: inline-block";
            }
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
    </>
  );
};

export default TagInput;
