import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  CampaignData,
} from "../../../components/campaigns/types";
import { axiosBaseQuery } from "../../../utils/axiosBaseQuery";
import {SmartScreenItem} from "../../../components/smartScreen/types";
import {CampaignUrlAssign, CampaignUrlCreate, CampaignUrlItem, CampaignUrlUpdateStatus} from "./types";

export const smartScreenApi = createApi({
  reducerPath: "smartScreenApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_HOST || "http://localhost:8000",
  }),

  endpoints: (build) => ({
    fetchUrls: build.query<CampaignUrlItem[], any>({
      query: () => ({
        url: "/campaign_urls/",
        method: "get",
      }),
    }),
    updateStatus: build.mutation<any,  { id: number; data: CampaignUrlUpdateStatus }>({
      query: ({ id, data }) => ({
        url: `/campaign_urls/${id}/status`,
        method: "patch",
        data: data,
      }),
    }),
    createUrl: build.mutation<any,  { data: CampaignUrlCreate }>({
      query: ({ data }) => ({
        url: `/campaign_urls/`,
        method: "post",
        data: data,
      }),
    }),
    assignCampaign: build.mutation<any,  { id: number; data: CampaignUrlAssign }>({
      query: ({ id, data }) => ({
        url: `/campaign_urls/${id}/assign`,
        method: "post",
        data: data,
      }),
    }),
  }),
});
