import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import Table from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import {
  Condition,
  IRuleCampaigns,
  RuleItem,
  RunningRuleInterface,
} from "./types";
import { Button, Popconfirm, Switch, Tag, message, Tooltip } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import axiosInstance from "../../utils/Interceptor";
import { rulesApi } from "../../redux/services/rulesApi";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import {
  addSelectedRule,
  addSelectedRulesList,
  clearSelectedRules,
  removeSelectedRule,
} from "../../redux/reducers/rules";
import { AccountInterface } from "../bestAd/types";

const RulesTable: React.FC<{
  data: RuleItem[];
  status: string;
  refetch: () => any;
  userRulePermission?: boolean;
  runningRule?: RunningRuleInterface;
}> = ({ data, status, refetch, userRulePermission, runningRule }) => {
  const [updateRuleStatus] = rulesApi.useUpdateRuleStatusMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [executeNowRule, { status: executeStatus }] =
    rulesApi.useLazyExecuteNowRuleQuery();
  const [duplicateRule] = rulesApi.useLazyDuplicateRuleQuery();
  const handleDelete = async (id: number) => {
    await axiosInstance.delete(`rule/${id}`);
    refetch();
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleUpdateStatus = async (ruleId: number) => {
    await updateRuleStatus(ruleId);
    refetch();
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Successfully executed",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Error",
    });
  };

  const successDuplicate = () => {
    messageApi.open({
      type: "success",
      content: "Successfully duplicated",
    });
  };

  const handleExecuteNow = async (ruleId: number, ruleAction: string) => {
    const result = await executeNowRule(ruleId);
    if (ruleAction === "Best Ads per account") {
      navigate("/best_ad", {
        state: {
          action: result.data as Array<AccountInterface>,
        },
      });
    } else {
      if (result.status === "fulfilled") {
        success();
      } else if (result.status === "rejected") {
        error();
      }
      refetch();
    }
  };

  const handleDuplicateRule = async (rule_id: number) => {
    const result = await duplicateRule(rule_id);
    if (result.status === "fulfilled") {
      successDuplicate();
    } else if (result.status === "rejected") {
      error();
    }
    refetch();
  };
  const [ruleData, setRuleData] = useState(data);

  useEffect(() => {
    setRuleData(data);
  }, [data]);

  useEffect(() => {
    if (runningRule) {
      setRuleData((prevRuleData) =>
        prevRuleData.map((ruleItem) =>
          ruleItem.id === runningRule.id
            ? { ...ruleItem, isRunning: runningRule.status }
            : ruleItem,
        ),
      );
    }
  }, [runningRule]);

  const Campaigns = <Tag color="blue">Campaigns</Tag>;
  const Accounts = <Tag color="green">Accounts</Tag>;

  const title = (
    <div>
      {Campaigns} {Accounts}
    </div>
  );
  const accountActions = ["Best Ads per account"];
  const columns: ColumnsType<RuleItem> = [
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      width: 150,
      render: (id, field) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <Switch
              disabled={
                !userRulePermission ||
                field.action.name === "Best Ads per account"
              }
              onChange={() => userRulePermission && handleUpdateStatus(id)}
              size="small"
              checked={data?.find((item) => item.id === id)?.isActive}
            />
          </span>
          <Popconfirm
            disabled={!userRulePermission}
            title="Duplicate the rule"
            description="Are you sure to duplicate this rule?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => userRulePermission && handleDuplicateRule(id)}
          >
            <span
              style={{
                marginTop: "5%",
                marginLeft: "5%",
                cursor: userRulePermission ? "pointer" : "not-allowed",
              }}
            >
              <CopyOutlined style={{ fontSize: "20px", color: "gray" }} />
            </span>
          </Popconfirm>
          <Popconfirm
            disabled={!userRulePermission}
            title="Delete the rule"
            description="Are you sure to delete this rule?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => userRulePermission && handleDelete(id)}
          >
            <span
              style={{
                marginTop: "5%",
                marginLeft: "5%",
                cursor: userRulePermission ? "pointer" : "not-allowed",
              }}
            >
              <DeleteOutlined
                style={{
                  fontSize: "20px",
                  color: userRulePermission ? "rgba(255, 0, 0, 0.8)" : "gray",
                }}
              />
            </span>
          </Popconfirm>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id, action, dateInterval, schedule }) => (
        <Link
          state={{
            action: action.name,
            actionId: action.id,
            intervalId: dateInterval?.id,
            scheduleId: schedule
              ? schedule.schedule !== "Best Ads per account"
                ? schedule.id
                : undefined
              : undefined,
          }}
          to={`${id}`}
        >
          {name}
        </Link>
      ),
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 150,
      render: (item, field) => {
        const isValid = field.action.name !== "Best Ads per account";
        return (
          <div>
            {item ? (
              <Tag color={isValid ? "green" : "default"}>Running</Tag>
            ) : (
              <Tag color={isValid ? "red" : "default"}>Paused</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Execution Status",
      dataIndex: "isRunning",
      key: "isRunning",
      width: 150,
      render: (item, field) => {
        const isValid = field.action.name !== "Best Ads per account";
        return (
          <div>
            {item ? (
              <Tag color={isValid ? "green" : "default"}>In Progress</Tag>
            ) : (
              <Tag color={isValid ? "red" : "default"}>Not in progress</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "action",
      key: "type",
      render: (action) => <div>{action.name}</div>,
    },
    {
      title: "Conditions",
      dataIndex: "conditions",
      key: "conditions",
      width: 200,
      render: (conditions: Condition[]) =>
        conditions?.map((item) => (
          <Tag key={item.id} color="orange">{`${
            item.ifValue === "cost" ? "FF Cost" : item.ifValue
          } ${
            item.condition === "less"
              ? "<"
              : item.condition === "greater"
              ? ">"
              : item.condition === "less_or_equal"
              ? "<="
              : ">="
          } ${item.value}`}</Tag>
        )),
    },
    {
      title: "Interval",
      dataIndex: "dateInterval",
      key: "dateInterval",
      render: (item, field) => (
        <div>
          {item?.interval
            ? item?.interval
            : `${field.specificInterval?.dateFrom} - ${field.specificInterval?.dateTo}`}
        </div>
      ),
    },
    {
      title: "Schedule",
      dataIndex: "schedule",
      key: "schedule",
      ellipsis: true,
      width: 170,
      render: (item, field) => {
        const actionExists = accountActions.some(
          (item) => item === field.action.name,
        );
        if (actionExists) {
          return <div></div>;
        } else {
          return <div>{item.schedule}</div>;
        }
      },
    },
    {
      title: title,
      dataIndex: "ruleCampaigns",
      key: "ruleCampaigns",
      width: 450,
      render: (item: IRuleCampaigns[], field) => {
        const actionExists = accountActions.some(
          (item) => item === field.action.name,
        );
        if (actionExists) {
          if (field.ruleAccounts) {
            const displayedAccounts = field.ruleAccounts
              .slice(0, 3)
              .map((acc, index) => (
                <Tag color="green" key={index}>
                  {acc.accountRel.accountName ??
                    acc.accountRel.externalAccountId}
                </Tag>
              ));
            return <div>{displayedAccounts}</div>;
          }
        } else {
          if (field.isActiveCampaigns) {
            return (
              <Tag color="blue" key={field.id}>
                All active campaigns
              </Tag>
            );
          } else {
            const displayedCampaigns = item.slice(0, 3).map((camp, index) => (
              <Tag color="blue" key={index}>
                {camp.campaignsRel.name}
              </Tag>
            ));
            return <div>{displayedCampaigns}</div>;
          }
        }
      },
    },
    {
      title: "Execute Now",
      dataIndex: "id",
      key: "executeNow",
      render: (id, field) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            disabled={!userRulePermission || field.isRunning}
            // loading={executeStatus == "pending" && id == loadingId}
            onClick={() => handleExecuteNow(id, field.action.name)}
          >
            Execute now
          </Button>
        </div>
      ),
      width: 200,
    },
  ];
  const rowSelection: TableRowSelection<RuleItem> = {
    onChange: (selectedRowKeys, selectedRows) => {},
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        dispatch(addSelectedRule(record.id));
      } else {
        dispatch(removeSelectedRule(record.id));
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      const data = selectedRows.map((item) => item.id);
      if (selected) {
        dispatch(clearSelectedRules());
        dispatch(addSelectedRulesList(data));
      } else {
        dispatch(clearSelectedRules());
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.action.name === "Best Ads per account",
    }),
  };
  return (
    <>
      {contextHolder}
      <Table
        scroll={{ x: 2000, y: 700 }}
        rowSelection={userRulePermission ? rowSelection : undefined}
        columns={columns}
        dataSource={ruleData?.map((item) => ({ ...item, key: item.id }))}
        loading={status !== "fulfilled"}
      />
    </>
  );
};

export default RulesTable;
