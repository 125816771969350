import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { Modal, Form, Input, Button, message } from "antd";
import {smartScreenApi} from "../../redux/services/smartScreenApi";

interface AddUrlModalProps {
  visible: boolean;
  onCancel: () => void;
  refetch: () =>void;
}

interface ApiResponse {
  data?: any;
  error?: any;
}

const AddUrlModal: React.FC<AddUrlModalProps> = ({ visible, onCancel, refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [createUrl] = smartScreenApi.useCreateUrlMutation();
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Call your API function to add the campaign URL
        const response: ApiResponse = await createUrl({ data: { url: values.url }});

        if (response.error === undefined ) {
            message.success("URL added successfully");
            form.resetFields();
            onCancel();
            refetch();
        } else {
            message.error(response.error.status == 400
                ? response.error?.data?.detail : response.error?.data?.detail[0].msg
            );
        }
    } catch (error: any) {
      message.error("Failed to add URL");
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal
      title="Add Url"
      visible={visible}
      onCancel={onCancel}
      footer={null} // Remove footer if not needed
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="url"
          rules={[
            { required: true, message: "URL is required" },
            { type: "url", message: "Please enter a valid URL" }
          ]}
        >
          <Input placeholder="Enter URL" />
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Add
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUrlModal;
