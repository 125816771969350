import React, { useEffect, useState } from "react";
import { message, Table, Tag } from "antd";
import { LogsFilterInterface, RuleLogsItem } from "../../types";
import { rulesApi } from "../../../../redux/services/rulesApi";
import type { ColumnsType } from "antd/es/table";
import LogFilter from "../../../../shared/logFilter";
import { DownloadCSV } from "../../../../shared/downloadCsv/downloadCSV";

const ChangeAdBidModifier: React.FC<{
  ruleColumns: ColumnsType<RuleLogsItem>;
  ruleId?: number;
  buttonClick?: boolean;
  resetButtonClick?: () => void;
  ruleAction: string;
}> = ({ ruleColumns, ruleId, buttonClick, resetButtonClick, ruleAction }) => {
  const action = "Change ad bid modifier";
  const [messageApi, contextHolder] = message.useMessage();
  const [logsFilterState, setLogsFilterState] = useState<LogsFilterInterface>({
    action: action,
  });
  const { data, status } = rulesApi.useFetchRulesByActionQuery(logsFilterState);
  const [getDetailLogs] = rulesApi.useLazyFetchRulesByActionByRuleIdQuery();

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Error",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!ruleId || !buttonClick || ruleAction !== action) {
        return;
      }
      resetButtonClick && resetButtonClick();
      return await getDetailLogs({
        ...logsFilterState,
        rule_id: ruleId,
        limit: 100,
      })
        .unwrap()
        .then((value) => {
          if (value) {
            DownloadCSV(action, value, ruleId);
          }
        })
        .catch(() => {
          error();
        });
    };

    fetchData();
  }, [ruleId, buttonClick, ruleAction]);

  const onFilterChange = (data: LogsFilterInterface) => {
    setLogsFilterState(data);
  };
  const expandedRowRender = (record: RuleLogsItem) => {
    const columns = [
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) =>
          text === "SUCCESS" ? (
            <Tag color="green">Success</Tag>
          ) : text === "FAILED" ? (
            <Tag color="red">Failed</Tag>
          ) : (
            <Tag color="orange">Not Affected</Tag>
          ),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text: string) => {
          const date = new Date(text);
          return <div>{date.toLocaleString()}</div>;
        },
      },
      {
        title: "Old Value",
        dataIndex: "ruleConfig",
        key: "oldValue",
        width: 250,
        render: (item: string) => {
          const arr = JSON.parse(item)["old_values"];
          return arr.length > 0
            ? arr.map(
                (bid: { id: string; cpcAdjustment: number }, idx: number) => (
                  <div
                    key={"oldValue" + JSON.parse(item)["campaign_id"] + idx}
                  >{`${bid.id}: ${bid.cpcAdjustment}`}</div>
                ),
              )
            : "";
        },
      },
      {
        title: "New Value",
        dataIndex: "ruleConfig",
        key: "newValue",
        width: 250,
        render: (item: string) => {
          const arr = JSON.parse(item)["new_values"];
          return arr.length > 0
            ? arr.map(
                (bid: { id: string; cpcAdjustment: number }, idx: number) => (
                  <div
                    key={"newValue" + JSON.parse(item)["campaign"] + idx}
                  >{`${bid.id}: ${bid.cpcAdjustment}`}</div>
                ),
              )
            : "";
        },
      },
      {
        title: "Campaign id",
        dataIndex: "ruleConfig",
        key: "campaign_id",
        render: (item: string) => <div>{JSON.parse(item)["campaign_id"]}</div>,
      },
      {
        title: "Campaign",
        dataIndex: "ruleConfig",
        key: "campaign",
        render: (item: string) => <div>{JSON.parse(item)["campaign"]}</div>,
      },
      {
        title: "Account",
        dataIndex: "ruleConfig",
        key: "account",
        render: (item: string) => <div>{JSON.parse(item)["account"]}</div>,
      },
      {
        title: "Error Message",
        dataIndex: "errorMessage",
        key: "errorMessage",
        render: (item: string) => {
          if (item) {
            const parsedObj = JSON.parse(item);
            if (parsedObj && "message" in parsedObj) {
              return <div>{parsedObj["message"]}</div>;
            }
          }
        },
      },
    ];
    return (
      <div style={{ width: "75vw", overflowX: "auto" }}>
        <Table
          style={{ overflowY: "auto" }}
          columns={columns}
          dataSource={
            record.logs
              ? record?.logs.map((item, index) => ({
                  ...item,
                  key: index,
                }))
              : []
          }
          pagination={false}
        />
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <LogFilter action={action} onFilterChange={onFilterChange} />
      <Table
        style={{ width: "75vw" }}
        columns={ruleColumns}
        expandable={{ expandedRowRender }}
        dataSource={data?.map((item) => ({ ...item, key: item.id }))}
        loading={status !== "fulfilled"}
      />
    </>
  );
};

export default ChangeAdBidModifier;
