import React, { useState, ReactNode } from "react";
import {
  AppstoreOutlined,
  FontColorsOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  SearchOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  FundProjectionScreenOutlined
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Row, Col, AutoComplete } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { IHeader, ITabContent } from "../campaigns/types";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { getTodayFormatted } from "../../utils";

const { Header, Sider, Content } = Layout;

const HeaderContent: React.FC<IHeader> = ({ collapsed, setCollapsed }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [date, setDate] = useState(String(getTodayFormatted()));
  const [dateTo, setDateTo] = useState(String(getTodayFormatted()));

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const { data } = campaignsApi.useFetchAllCampaignsQuery({
    date_from: date,
    date_to: dateTo,
    filterValue: "",
  });

  const filteredData = data?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.externalId.toString().includes(searchValue.toLowerCase()),
  );

  const handleSelect = (value: string) => {
    const campaign = data?.find((item) => item.name === value);
    navigate(`/campaigns/${campaign?.id}/ads`);
  };

  return (
    <Header style={{ background: colorBgContainer, paddingInline: "0px" }}>
      <Row align="middle" style={{ height: "100%" }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
          span={2}
        >
          <div className="logo">Logo</div>
        </Col>
        <Col
          span={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            borderRight: "1px solid rgba(0, 0, 0, 0.2)",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "1.5rem",
            width: "800px",
          }}
          span={6}
        >
          <AutoComplete
            style={{
              width: "600px",
            }}
            value={searchValue}
            onChange={handleSearch}
            onSelect={handleSelect}
            placeholder={
              <span>
                <SearchOutlined /> Search Campaign
              </span>
            }
          >
            {filteredData?.map((campaign) => (
              <AutoComplete.Option key={campaign.id} value={campaign.name}>
                {campaign.name}
              </AutoComplete.Option>
            ))}
          </AutoComplete>
        </Col>
      </Row>
    </Header>
  );
};

const TabContent: React.FC<ITabContent> = ({ collapsed, children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState(location.pathname);
  const handleMenuSelect = (menuItem: any) => {
    setSelectedMenuItem(menuItem.key);
    navigate(menuItem.key);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Sider width={230} trigger={null} collapsible collapsed={collapsed}>
          <Menu
            onClick={({ key }) => navigate(key)}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedMenuItem]}
            onSelect={handleMenuSelect}
            items={[
              {
                key: "1",
                icon: <UnorderedListOutlined />,
                label: "Dashboard",
              },
              {
                key: "/campaigns",
                icon: <NotificationOutlined />,
                label: "Campaigns",
              },
              {
                key: "/ad_creator",
                icon: <FontColorsOutlined />,
                label: "Ad Creator",
              },
              {
                key: "2",
                icon: <AppstoreOutlined />,
                label: "Rules",
                children: [
                  {
                    key: "/rules",
                    label: "Rules List",
                  },
                  {
                    key: "/rules/logs",
                    label: "Rules Logs",
                  },
                ],
              },
              {
                key: "/accounts",
                icon: <TeamOutlined />,
                label: "Accounts",
              },
              {
                key: "/smartscreen",
                icon: <FundProjectionScreenOutlined />,
                label: "SmartScreen",
              },
            ]}
          />
        </Sider>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <HeaderContent setCollapsed={setCollapsed} collapsed={collapsed} />
      <TabContent collapsed={collapsed}>{children}</TabContent>
    </Layout>
  );
};
