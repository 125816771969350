import { useLocation, useNavigate } from "react-router-dom";
import { Card, Collapse, CollapseProps, Typography } from "antd";
import React, { useEffect } from "react";
import { AccountInterface, AdRuleInterface } from "./types";
import { DollarOutlined } from "@ant-design/icons";

const BestAdComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.action as Array<AccountInterface>;

  useEffect(() => {
    if (!data) {
      navigate("/rules");
    }

    return () => {
      window.history.replaceState({}, document.title);
    };
  }, [data]);
  const clearedData = (
    location.state?.action as Array<AccountInterface>
  )?.filter(
    (accountData) =>
      (accountData.ads?.ctrAds && accountData.ads?.ctrAds?.length > 0) ||
      (accountData.ads?.cvrAds && accountData.ads?.cvrAds?.length > 0),
  );
  const Accounts: CollapseProps["items"] = clearedData?.map(
    (accountData: AccountInterface) => ({
      key: accountData.account,
      label: (
        <span>
          <DollarOutlined /> {accountData.account}
        </span>
      ),
      children: (
        <div style={{ maxWidth: "100%" }}>
          {accountData.ads?.ctrAds ? (
            <Typography style={{ fontWeight: 600 }}>CTR</Typography>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
            }}
          >
            {accountData.ads?.ctrAds?.map(
              (ad: AdRuleInterface, index: number) => (
                <Card
                  key={`${index}-${ad.title}`}
                  hoverable
                  style={{
                    width: 300,
                    maxWidth: 300,
                    minHeight: 300,
                    fontSize: 12,
                    margin: "0 auto",
                    marginBottom: 25,
                  }}
                  cover={
                    <img
                      alt="example"
                      style={{ width: 300, height: 200 }}
                      src={ad.thumbnail_url}
                    />
                  }
                >
                  <Typography style={{ fontSize: 14, display: "inline-block" }}>
                    <Typography style={{ fontWeight: 600 }}>ID:</Typography>
                    {"  "}
                    {ad.ad_id}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      display: "inline-block",
                    }}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      Headline:
                    </Typography>{" "}
                    {ad.title}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      Spent:
                    </Typography>{" "}
                    {ad.spent && ad.spent > 0 ? ad.spent.toFixed(4) : 0}$
                  </Typography>

                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      Roas:
                    </Typography>{" "}
                    {ad.roas && ad.roas > 0 ? ad.roas?.toFixed(7) : 0}%
                  </Typography>

                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      CTR:
                    </Typography>{" "}
                    {ad.ctr && ad.ctr > 0 ? ad.ctr?.toFixed(7) : 0}%
                  </Typography>

                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      CVR:
                    </Typography>{" "}
                    {ad.cvr && ad.cvr > 0 ? ad.cvr?.toFixed(7) : 0}%
                  </Typography>
                </Card>
              ),
            )}
          </div>
          {accountData.ads?.cvrAds ? (
            <Typography style={{ fontWeight: 600 }}>CVR</Typography>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
            }}
          >
            {accountData.ads?.cvrAds?.map(
              (ad: AdRuleInterface, index: number) => (
                <Card
                  key={`${index}-${ad.title}`}
                  hoverable
                  style={{
                    width: 300,
                    maxWidth: 300,
                    minHeight: 300,
                    fontSize: 12,
                    margin: "0 auto",
                    marginBottom: 25,
                  }}
                  cover={
                    <img
                      alt="example"
                      style={{ width: 300, height: 200 }}
                      src={ad.thumbnail_url}
                    />
                  }
                >
                  <Typography style={{ fontSize: 14, display: "inline-block" }}>
                    <Typography style={{ fontWeight: 600 }}>ID:</Typography>
                    {"  "}
                    {ad.ad_id}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      display: "inline-block",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      Headline:
                    </Typography>{" "}
                    {ad.title}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      Spent:
                    </Typography>{" "}
                    {ad.spent && ad.spent > 0 ? ad.spent.toFixed(4) : 0}$
                  </Typography>

                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      Roas:
                    </Typography>{" "}
                    {ad.roas && ad.roas > 0 ? ad.roas?.toFixed(7) : 0}%
                  </Typography>

                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      CTR:
                    </Typography>{" "}
                    {ad.ctr && ad.ctr > 0 ? ad.ctr?.toFixed(7) : 0}%
                  </Typography>

                  <Typography style={{ fontSize: 14 }}>
                    <Typography
                      style={{ fontWeight: 600, display: "inline-block" }}
                    >
                      CVR:
                    </Typography>{" "}
                    {ad.cvr && ad.cvr > 0 ? ad.cvr?.toFixed(7) : 0}%
                  </Typography>
                </Card>
              ),
            )}
          </div>
        </div>
      ),
    }),
  );

  return (
    <div style={{ width: "100%" }}>
      <h3 style={{ margin: "0 auto", maxWidth: "200px" }}>
        Best Ads Per Accounts
      </h3>
      {Accounts
        ? Accounts.map((account, index) => {
            const array = [account];
            return <Collapse items={array} style={{ margin: "25px 0" }} />;
          })
        : null}
    </div>
  );
};

export default BestAdComponent;
