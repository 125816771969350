import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { format } from "date-fns";
import { DatePicker, Popover, Radio, RadioChangeEvent } from "antd";
import { getDayFormatted, getTodayFormatted } from "../../../utils";
import { CalendarOutlined } from "@ant-design/icons";

const DatePickerComponent: React.FC<{
  onChangeDateFrom: (date: string) => void;
  onChangeDateTo: (date: string) => void;
  onChangeRangePicker: (arg: any) => void;
  setRangePickerDate?: (arg: any) => void;
  rangePickerDate: any;
  dateFrom?: string;
  dateTo?: string;
}> = ({
  onChangeDateFrom,
  onChangeDateTo,
  onChangeRangePicker,
  setRangePickerDate,
  rangePickerDate,
  dateFrom,
  dateTo,
}) => {
  const { RangePicker } = DatePicker;
  const [radioButtonDate, setRadioButtonDate] = useState<string | undefined>(
    undefined,
  );
  const onChangeDatePicker = (event: any) => {
    if (event) {
      const date_from = dayjs(event[0]);
      const date_to = dayjs(event[1]);
      const formatted_date_from = format(date_from.toDate(), "yyyy-MM-dd");
      const formatted_date_to = format(date_to.toDate(), "yyyy-MM-dd");
      onChangeDateFrom(formatted_date_from);
      onChangeDateTo(formatted_date_to);
      onChangeRangePicker(event);
      setRadioButtonDate("0");
    } else {
      onChangeDateFrom(getTodayFormatted());
      onChangeDateTo(getTodayFormatted());
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const datePickerData = useMemo(
    () => [
      {
        title: `${dateFrom} - ${dateTo}`,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
      {
        title: "Today",
        dateFrom: String(getTodayFormatted()),
        dateTo: String(getTodayFormatted()),
      },
      {
        title: "Yesterday",
        dateFrom: String(getDayFormatted(1, true)),
        dateTo: String(getDayFormatted(1, true)),
      },
      {
        title: "Last 3 days",
        dateFrom: String(getDayFormatted(3, true)),
        dateTo: String(getTodayFormatted()),
      },
      {
        title: "Last 7 days",
        dateFrom: String(getDayFormatted(7, true)),
        dateTo: String(getTodayFormatted()),
      },
      {
        title: "Last 14 days",
        dateFrom: String(getDayFormatted(14, true)),
        dateTo: String(getTodayFormatted()),
      },
      {
        title: "Last 30 days",
        dateFrom: String(getDayFormatted(30, true)),
        dateTo: String(getTodayFormatted()),
      },
      {
        title: "This month",
        dateFrom: String(getDayFormatted(1, false)),
        dateTo: String(getTodayFormatted()),
      },
      {
        title: "Last 90 days",
        dateFrom: String(getDayFormatted(90, true)),
        dateTo: String(getTodayFormatted()),
      },
      {
        title: "Last 6 months",
        dateFrom: String(getDayFormatted(6, false)),
        dateTo: String(getTodayFormatted()),
      },
    ],
    [dateFrom, dateTo],
  );

  useEffect(() => {
    if (radioButtonDate) {
      if (radioButtonDate !== "0") {
        onChangeDateFrom(
          datePickerData[radioButtonDate ? +radioButtonDate : 0].dateFrom ?? "",
        );
        onChangeDateTo(
          datePickerData[radioButtonDate ? +radioButtonDate : 0].dateTo ?? "",
        );
        setRangePickerDate && setRangePickerDate(undefined);
      }
      setOpen(false);
    }
  }, [radioButtonDate]);

  const onChange = (e: RadioChangeEvent) => {
    setRadioButtonDate(e.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "5px" }}>
        <RangePicker
          allowClear={false}
          disabledDate={(current) => {
            let future = dayjs(new Date(format(new Date(), "yyyy-MM-dd"))).add(
              1,
              "day",
            );
            return current && current >= future;
          }}
          onChange={(event) => onChangeDatePicker(event)}
          value={rangePickerDate}
        />
      </div>
      <Popover
        content={
          <div
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Radio.Group onChange={onChange} value={radioButtonDate}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Radio.Button value="1" style={{ marginBottom: "5px" }}>
                  Today
                </Radio.Button>
                <Radio.Button value="2" style={{ marginBottom: "5px" }}>
                  Yesterday
                </Radio.Button>
                <Radio.Button value="3" style={{ marginBottom: "5px" }}>
                  Last 3 days
                </Radio.Button>
                <Radio.Button value="4" style={{ marginBottom: "5px" }}>
                  Last 7 days
                </Radio.Button>
                <Radio.Button value="5" style={{ marginBottom: "5px" }}>
                  Last 14 days
                </Radio.Button>
                <Radio.Button value="6" style={{ marginBottom: "5px" }}>
                  Last 30 days
                </Radio.Button>
                <Radio.Button value="7" style={{ marginBottom: "5px" }}>
                  This month
                </Radio.Button>
                <Radio.Button value="8" style={{ marginBottom: "5px" }}>
                  Last 90 days
                </Radio.Button>
                <Radio.Button value="9" style={{ marginBottom: "5px" }}>
                  Last 6 month
                </Radio.Button>
              </div>
            </Radio.Group>
          </div>
        }
        title="Dates"
        trigger="click"
        placement={"bottom"}
        open={open}
        onOpenChange={handleOpenChange}
      >
        <div
          style={{
            maxWidth: "100px",
            display: "flex",
            alignItems: "center",
            border: "0.1px solid grey",
            borderRadius: "5px",
            padding: "0 10px",
            cursor: "pointer",
          }}
        >
          <CalendarOutlined style={{ fontSize: "20px" }} />
        </div>
      </Popover>
    </div>
  );
};

export default DatePickerComponent;
