import React from "react";
import { message, Popconfirm, Table, Tag } from "antd";
import { Account, IDataSource } from "./types";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import axiosInstance from "../../utils/Interceptor";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";

const SourceTable: React.FC<{
  data: IDataSource[];
  status: string;
  refetch: () => any;
  isAllowToUpdate?: boolean;
}> = ({ data, status, refetch, isAllowToUpdate }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const handleDelete = async (id: number) => {
    await axiosInstance
      .delete(`sources/${id}`)
      .then((result) => {
        if (result.status === 200) {
          refetch();
        }
      })
      .catch(() => {
        error();
      });
  };

  const navigate = useNavigate();
  const error = () => {
    messageApi.open({
      type: "error",
      content: "Error",
    });
  };
  const handleNavigateUpdateSource = (id: number) => {
    navigate(`/source/${id}/update`);
  };

  const columns: ColumnsType<IDataSource> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { id, name }) => (
        <div
          style={{
            cursor: isAllowToUpdate ? "pointer" : "default",
            color: isAllowToUpdate ? "blue" : "black",
          }}
          onClick={() => isAllowToUpdate && handleNavigateUpdateSource(id)}
        >
          {name}
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => {
        const date = new Date(text);
        return <div>{date.toLocaleString()}</div>;
      },
    },
    {
      title: "Account External ID",
      dataIndex: "account",
      key: "externalAccountId",
      render: (account: Account[]) => {
        return (
          <div>
            {account.map((item) => (
              <Tag
                color={item.isActive ? "green" : "red"}
                key={item.externalAccountId}
              >
                {item.accountName ?? item.externalAccountId}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "Source Type",
      dataIndex: "sourceType",
      key: "sourceType",
    },
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "",
      dataIndex: "id",
      key: "delete",
      render: (id: number) => (
        <Popconfirm
          disabled={!isAllowToUpdate}
          title="Delete the source"
          description="Are you sure to delete this source?"
          icon={
            isAllowToUpdate ? (
              <QuestionCircleOutlined style={{ color: "red" }} />
            ) : (
              <QuestionCircleOutlined style={{ color: "gray" }} />
            )
          }
          onConfirm={() => handleDelete(id)}
        >
          <span
            style={{ marginTop: "5%", marginLeft: "5%", cursor: "pointer" }}
          >
            {isAllowToUpdate ? (
              <DeleteOutlined
                style={{ fontSize: "20px", color: "rgba(255, 0, 0, 0.8)" }}
              />
            ) : (
              <DeleteOutlined
                style={{
                  fontSize: "20px",
                  color: "gray",
                  cursor: "not-allowed",
                }}
              />
            )}
          </span>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Table
        loading={status !== "fulfilled"}
        dataSource={data?.map((item, idx) => ({ ...item, key: idx }))}
        columns={columns}
      />
    </>
  );
};

export default SourceTable;
