import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "../../../utils/axiosBaseQuery";

export const adCreatorApi = createApi({
  reducerPath: "adCreatorApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_HOST || "http://localhost:8000",
  }),

  endpoints: (build) => ({
    adCreate: build.mutation<string, FormData>({
      query: (data) => ({
        url: "/ads_create/create",
        method: "post",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }),
    }),
  }),
});
