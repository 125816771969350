import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "../../../utils/axiosBaseQuery";
import { Account } from "../../../components/account/types";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_HOST || "http://localhost:8000",
  }),

  endpoints: (build) => ({
    fetchAccounts: build.query<Account[], boolean>({
      query: (value) => ({
        url: `/accounts/all`,
        method: "get",
      }),
    }),
  }),
});
