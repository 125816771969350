import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./components/auth/login";
import PrivateRoute from "./utils/PrivateRoute";
import { MainLayout } from "./components/layout";
import Publishers from "./components/campaigns/campaignTable/publisherTable";
import Campaigns from "./components/campaigns";
import SmartScreen from "./components/smartScreen";
import Ads from "./components/campaigns/campaignTable/adsTable";
import RuleDetail from "./components/rules/ruleDetail";
import Rules from "./components/rules";
import { EditRule } from "./components/rules/ruleDetail/editRule";
import { RuleLogs } from "./components/rules/ruleLogs";
import { Source } from "./components/account";
import SourceCreateForm from "./components/account/createSourceForm";
import SourceUpdatePage from "./components/account/updateSourcePage";
import Sections from "./components/campaigns/campaignTable/sectionTable";
import AdCreator from "./components/adCreator";
import BestAdComponent from "./components/bestAd";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="*" element={<Navigate to="/campaigns" />} />
          <Route
            path="/campaigns"
            element={
              <MainLayout>
                <Campaigns />
              </MainLayout>
            }
          />
          <Route
            path="/rules"
            element={
              <MainLayout>
                <Rules />
              </MainLayout>
            }
          />
          <Route
            path="/rules/logs"
            element={
              <MainLayout>
                <RuleLogs />
              </MainLayout>
            }
          />
          <Route
            path="/rules/:id"
            element={
              <MainLayout>
                <EditRule />
              </MainLayout>
            }
          />
          <Route
            path="/rule/create"
            element={
              <MainLayout>
                <RuleDetail />
              </MainLayout>
            }
          />
          <Route
            path="campaigns/:id/ads"
            element={
              <MainLayout>
                <Ads />
              </MainLayout>
            }
          />
          <Route
            path="campaigns/:id/publishers"
            element={
              <MainLayout>
                <Publishers />
              </MainLayout>
            }
          />
          <Route
            path="campaigns/:id/sections"
            element={
              <MainLayout>
                <Sections />
              </MainLayout>
            }
          />
          <Route
            path="/accounts"
            element={
              <MainLayout>
                <Source />
              </MainLayout>
            }
          />
          <Route
            path="/source/create"
            element={
              <MainLayout>
                <SourceCreateForm />
              </MainLayout>
            }
          />
          <Route
            path="/source/:id/update/"
            element={
              <MainLayout>
                <SourceUpdatePage />
              </MainLayout>
            }
          />
        </Route>
        <Route
          path="/ad_creator"
          element={
            <MainLayout>
              <AdCreator />
            </MainLayout>
          }
        />
        <Route
          path="/best_ad"
          element={
            <MainLayout>
              <BestAdComponent />
            </MainLayout>
          }
        />
        <Route
          path="/smartscreen"
          element={
            <MainLayout>
                <SmartScreen />
            </MainLayout>
          }
        />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
