import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { Modal, Form, Input, Button, message } from "antd";
import {smartScreenApi} from "../../redux/services/smartScreenApi";
import {MultiSelect} from "react-multi-select-component";
import {CloseOutlined} from "@ant-design/icons";
import { campaignsApi } from "../../redux/services/campaignsApi";

interface AssignCampaignModalProps {
  id: number; // Define the type for the ID prop
  visible: boolean;
  onCancel: () => void;
  refetch: () =>void;
}

interface ApiResponse {
  data?: any;
  error?: any;
}

const AssignCampaignModal: React.FC<AssignCampaignModalProps> = ({ id, visible, onCancel, refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [assignCampaign] = smartScreenApi.useAssignCampaignMutation();
  const { data, status: campaignLoading } = campaignsApi.useFetchAllCampaignsQuery({filterValue: ""});

  const options = data?.map((item) => ({
    label: item.name,
    value: item.id,
  })) || [];

  const [selected, setSelected] = useState<Array<any>>([]);

  const [selectedCampaigns, setSelectedCampaigns] = useState<
    Array<number> | string
  >([]);

  const handleSelectedCampaign = (
    campaigns: Array<{ label: string; value: number }> | string,
  ) => {
    if (typeof campaigns !== "string") {
      setSelected(campaigns);
      const selectedValues = campaigns.map((campaign) => campaign.value);
      setSelectedCampaigns(selectedValues);
    }
  };

  const handleSubmit = async () => {

    try {
      setLoading(true);
      const values = await form.validateFields();
        const campaignIds: number[] = Array.isArray(selectedCampaigns) ? selectedCampaigns : [];
        await assignCampaign({
          id: id,
          data: {
            campaignIds : campaignIds
          }
        });
        onCancel();
        refetch();

        /*const response: ApiResponse = await createUrl({ data: { url: values.url }});
        if (response.error === undefined ) {
            message.success("URL added successfully");
            form.resetFields();
            onCancel();
            refetch();
        } else {
            message.error(response.error.status == 400
                ? response.error?.data?.detail : response.error?.data?.detail[0].msg
            );
        }*/
    } catch (error: any) {
      message.error("Failed to assign campaign");
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal
      title="Assign url to Campaign"
      visible={visible}
      onCancel={onCancel}
      footer={null} // Remove footer if not needed
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
            name="campaigns"
            id="campaigns"
            label=""
          >
            <div>
              <MultiSelect
                options={options}
                value={selected}
                onChange={handleSelectedCampaign}
                labelledBy="Select"
                ClearSelectedIcon={
                  <CloseOutlined style={{ color: "grey" }} />
                }
              />
            </div>
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Assign
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignCampaignModal;
