import TagInput from "./tagInput";
import type { CollapseProps, UploadProps } from "antd";
import { Button, Collapse, Form, Input, message, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import {
  CloseOutlined,
  EditOutlined,
  FileImageOutlined,
  FileOutlined,
  InboxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { MultiSelect } from "react-multi-select-component";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { AdCreateRequest, AdCreatorForm, ErrorCreateAdRequest } from "./types";
import { adCreatorApi } from "../../redux/services/adCreatorApi";
import { UploadFileStatus } from "antd/es/upload/interface";
const { Dragger } = Upload;

const AdCreator = () => {
  const [selected, setSelected] = useState<Array<any>>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<
    Array<number> | string
  >([]);
  const [tags, setTags] = useState<Array<string>>([]);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  useState<Array<string | number>>();
  const { data, status: campaignLoading } =
    campaignsApi.useFetchAllCampaignsQuery({
      filterValue: "",
    });
  const [createAd, { status: createStatus }] =
    adCreatorApi.useAdCreateMutation();

  useEffect(() => {
    form.setFieldsValue({ campaigns: selectedCampaigns });
  }, [selectedCampaigns, form]);

  useEffect(() => {
    form.setFieldValue("headlines", tags);
  }, [tags, form]);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Success",
    });
  };

  const error = (campaignIds: Array<string | number>) => {
    messageApi.open({
      type: "error",
      content: `Error campaigns: ${campaignIds}`,
    });
  };

  const campaignSubmitError = () => {
    messageApi.open({
      type: "error",
      content: `Select one or more campaigns.`,
    });
  };

  const handleSelectedCampaign = (
    campaigns: Array<{ label: string; value: number }> | string,
  ) => {
    if (typeof campaigns !== "string") {
      setSelected(campaigns);
      const selectedValues = campaigns.map((campaign) => campaign.value);
      setSelectedCampaigns(selectedValues);
    }
  };

  const handleSelectedTag = (tag: Array<string>) => {
    setTags(tag);
  };

  const options = data
    ? data?.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  const handleErrorCreate = (campaignIds: Array<string | number>) => {
    const stringIds: string[] = campaignIds.map((id) => String(id));

    const findIds: Array<string | number> = [];
    data?.forEach((campaign) => {
      if (stringIds && stringIds.includes(campaign.externalId.toString())) {
        findIds.push(campaign.id);
      }
    });
    if (Array.isArray(selectedCampaigns) && findIds) {
      const updatedSelectedCampaigns = selectedCampaigns.filter(
        (campaign) => !findIds.includes(campaign),
      );
      setSelectedCampaigns(updatedSelectedCampaigns);
    }
    const updatedSelected = selected.filter(
      (value) => !findIds.includes(value.value),
    );
    setSelected(updatedSelected);

    const updatedValues = updatedSelected.map((item) => item.value);
    const matchedCampaigns = data?.filter((campaign) =>
      updatedValues.includes(campaign.id),
    );
    const matchedExternalIds = matchedCampaigns?.map(
      (campaign) => campaign.externalId,
    );

    if (matchedExternalIds) {
      error(matchedExternalIds);
    }
  };

  const handleSuccessCreate = () => {
    form.resetFields();
    setSelected([]);
    setTags([]);
    setSelectedCampaigns([]);
    success();
  };
  const props: UploadProps = {
    name: "file",
    multiple: true,
    maxCount: 10,
    accept: "image/png, image/jpeg, image/jpg, image/webp, image/gif",
    customRequest() {},
    onChange(info) {
      info.file.status = "success" as UploadFileStatus;
    },
    onDrop() {},
  };

  const onFinish = async (values: AdCreatorForm) => {
    const files = values.fileList.fileList.map((val: any) => val.originFileObj);
    if (!files.length) {
      form.setFields([
        {
          name: "fileList",
          errors: ["this field is required"],
        },
      ]);
      return;
    }

    if (typeof values.campaigns == "string") {
      values.campaigns = selectedCampaigns;
    }

    if (!values.campaigns.length) {
      form.setFields([
        {
          name: "campaigns",
          errors: ["this field is required"],
        },
      ]);
      campaignSubmitError();
      return;
    }

    const data = {
      campaigns: values.campaigns,
      headlines: values.headlines,
      landing_page: values.landingPage,
      file_list: files,
    } as AdCreateRequest;

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value) && key != "file_list") {
        value.forEach((item) => {
          formData.append(key, item.toString());
        });
      } else if (key === "file_list" && Array.isArray(value)) {
        value.forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, value.toString());
      }
    });
    await createAd(formData)
      .unwrap()
      .then(() => {
        handleSuccessCreate();
      })
      .catch((val: ErrorCreateAdRequest) => {
        handleErrorCreate(val.data.detail);
      });
  };

  const WatchCampaigns = Form.useWatch("campaigns", form);

  useEffect(() => {
    if (!Array.isArray(WatchCampaigns) && WatchCampaigns?.length === 0) {
      form.setFields([
        {
          name: "campaigns",
          value: selectedCampaigns,
          errors: [],
        },
      ]);
    }
  }, [WatchCampaigns]);

  const CampaignSetupItems: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <span>
          <FileOutlined />
          {" Campaign Setup"}
        </span>
      ),
      children: (
        <div>
          <Typography style={{ fontWeight: "bold" }}>Campaigns</Typography>
          <Typography style={{ marginTop: "10px" }}>
            Select one or more campaigns.
          </Typography>
          <div style={{ margin: "20px 0" }}>
            {options && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
                name="campaigns"
                id="campaigns"
              >
                <div>
                  <MultiSelect
                    disabled={false}
                    options={options}
                    value={selected}
                    onChange={handleSelectedCampaign}
                    labelledBy="Select"
                    ClearSelectedIcon={
                      <CloseOutlined style={{ color: "grey" }} />
                    }
                  />
                </div>
              </Form.Item>
            )}
          </div>
        </div>
      ),
    },
  ];

  const SetupItems: CollapseProps["items"] = [
    {
      key: "2",
      label: (
        <span>
          <FileImageOutlined />
          {" Setup"}
        </span>
      ),
      children: (
        <div>
          <Typography style={{ fontWeight: "bold" }}>
            Landing page URL
          </Typography>
          <div style={{ margin: "20px 0" }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
              name="landingPage"
              id="landingPage"
            >
              <div>
                <Input placeholder={"Enter URL"} />
              </div>
            </Form.Item>

            <Typography style={{ marginTop: "10px", fontWeight: "bold" }}>
              Media
            </Typography>
            <Typography style={{ margin: "10px 0 10px" }}>
              Add up to 10 images or animated GIF
            </Typography>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
              name="fileList"
              id="fileList"
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </p>
              </Dragger>
            </Form.Item>
          </div>
        </div>
      ),
    },
  ];

  const SetupHeadlinesItems: CollapseProps["items"] = [
    {
      key: "3",
      label: (
        <span>
          <EditOutlined />
          {" Headline Setup"}
        </span>
      ),
      children: (
        <div>
          <Typography style={{ fontWeight: "bold" }}>Headlines</Typography>
          <Form.Item
            rules={[
              {
                required: true,
                message: "this field is required",
              },
            ]}
            name="headlines"
            id="headlines"
          >
            <TagInput
              handleSelectedTag={(tags: Array<string>) =>
                handleSelectedTag(tags)
              }
              selectedTag={tags}
              form={form}
            />
          </Form.Item>
        </div>
      ),
    },
  ];

  return campaignLoading == "fulfilled" ? (
    <div>
      <Form
        layout="vertical"
        form={form}
        name="control-ref"
        onFinish={onFinish}
        validateTrigger="onSubmit"
        style={{ maxWidth: 1200, marginBottom: 100 }}
      >
        {contextHolder}
        <Collapse
          items={CampaignSetupItems}
          style={{ margin: "25px 0" }}
          defaultActiveKey={["1"]}
        />
        <Collapse
          items={SetupItems}
          style={{ margin: "25px 0" }}
          defaultActiveKey={["2"]}
        />
        <Collapse
          items={SetupHeadlinesItems}
          style={{ margin: "25px 0" }}
          defaultActiveKey={["3"]}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={createStatus == "pending"}
          loading={createStatus == "pending"}
        >
          Submit
        </Button>
      </Form>
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

export default AdCreator;
