import { Button, Tabs, Tag } from "antd";
import PauseAd from "./pauseAd/pauseAd";
import { BlockPublishers } from "./blockPublishers";
import type { ColumnsType } from "antd/es/table";
import { Condition, DateInterval, RuleLogsItem, Schedule } from "../types";
import ChangePublisherBidModifier from "./changeBidModifier";
import { BlockSection } from "./blockSection";
import ChangeSectionBidModifier from "./changeSectionBidModifier";
import React, { useState } from "react";
import ChangeCampaignBid from "./changeCampaignBid";
import ChangeCampaignBudget from "./changeCampaignBudget";
import ChangeAdBidModifier from "./changeAdBidModifier";

export const RuleLogs = () => {
  const handleDownloadCsv = (action: string, id?: number) => {
    if (!id) {
      return;
    }
    setButtonClick(true);
    setRuleId(id);
    setDisabledButtons((prev) => ({ ...prev, [id]: true }));
    setTimeout(() => {
      setDisabledButtons((prev) => ({ ...prev, [id]: false }));
    }, 5000);
    setRuleAction(action);
  };
  const initialDisabledButtons: { [key: number]: boolean } = {};

  const [disabledButtons, setDisabledButtons] = useState(
    initialDisabledButtons,
  );

  const [loadingButtons, setLoadingButtons] = useState(initialDisabledButtons);
  const [ruleIdState, setRuleId] = useState<number | undefined>();
  const [buttonClick, setButtonClick] = useState(false);
  const [ruleAction, setRuleAction] = useState<string>("");

  const handleLoadingButtons = (ruleId: number, status: string) => {
    if (status === "pending") {
      setLoadingButtons((prev) => ({ ...prev, [ruleId]: true }));
    } else {
      setLoadingButtons((prev) => ({ ...prev, [ruleId]: false }));
    }
  };

  const columns: ColumnsType<RuleLogsItem> = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 150,
      render: (item: boolean) => (
        <div>
          {item ? (
            <Tag color="green">Running</Tag>
          ) : (
            <Tag color="red">Paused</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Condition",
      dataIndex: "conditions",
      key: "conditions",
      render: (conditions: Condition[]) =>
        conditions?.map((item) => (
          <Tag key={item.id} color="orange">{`${item.ifValue} ${
            item.condition === "less"
              ? "<"
              : item.condition === "greater"
              ? ">"
              : item.condition === "less_or_equal"
              ? "<="
              : ">="
          } ${item.value}`}</Tag>
        )),
    },
    {
      title: "Schedule",
      dataIndex: "schedule",
      key: "schedule",
      render: (schedule: Schedule) => <div>{schedule.schedule}</div>,
    },
    {
      title: "Interval",
      dataIndex: "dateInterval",
      key: "dateInterval",
      render: (item: DateInterval, field) => (
        <div>
          {item?.interval
            ? item?.interval
            : `${field?.specificInterval?.dateFrom} - ${field?.specificInterval?.dateTo}`}
        </div>
      ),
    },
    {
      title: "Download CSV",
      dataIndex: "rule_id",
      key: "rule_id",
      render: (rule_id, field) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => handleDownloadCsv(field.action.name, field.id)}
            disabled={disabledButtons[field.id] || loadingButtons[field.id]}
            loading={loadingButtons[field.id]}
          >
            Download CSV
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: "Pause ad",
            key: "1",
            children: (
              <PauseAd
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                handleLoadingButtons={(
                  ruleId: number,
                  detailLogsStatus: string,
                ) => handleLoadingButtons(ruleId, detailLogsStatus)}
                ruleAction={ruleAction}
              />
            ),
          },
          {
            label: "Block publishers",
            key: "2",
            children: (
              <BlockPublishers
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                ruleAction={ruleAction}
              />
            ),
          },
          {
            label: "Block section",
            key: "3",
            children: (
              <BlockSection
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                ruleAction={ruleAction}
              />
            ),
          },
          {
            label: "Change section bid modifier",
            key: "4",
            children: (
              <ChangeSectionBidModifier
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                ruleAction={ruleAction}
              />
            ),
          },
          {
            label: "Change publisher bid modifier",
            key: "5",
            children: (
              <ChangePublisherBidModifier
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                ruleAction={ruleAction}
              />
            ),
          },
          {
            label: "Change campaign bid",
            key: "6",
            children: (
              <ChangeCampaignBid
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                ruleAction={ruleAction}
              />
            ),
          },
          {
            label: "Change campaign budget",
            key: "7",
            children: (
              <ChangeCampaignBudget
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                ruleAction={ruleAction}
              />
            ),
          },
          {
            label: "Change ad bid modifier",
            key: "8",
            children: (
              <ChangeAdBidModifier
                ruleColumns={columns}
                ruleId={ruleIdState}
                buttonClick={buttonClick}
                resetButtonClick={() => setButtonClick(false)}
                ruleAction={ruleAction}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
