import {
  ChangeAdBidModifier,
  ChangeCampaignBid,
  ChangeCampaignBudget,
  ChangePublisherBidModifier,
  ChangeSectionBidModifier,
  RuleLogsItem,
} from "../../components/rules/types";

export const DownloadCSV = (
  rule_action: string,
  data?: RuleLogsItem[],
  ruleId?: number,
) => {
  if (!ruleId || !data) {
    return;
  }

  const handleConvertToCSV = (data: any) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    for (const item of data) {
      const values = headers.map((header) => item[header]);
      csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
  };

  const rulesLogs = data?.find((item) => item.id === ruleId);

  const escapeDoubleQuotes = (value: string) => {
    return `"${value.replace(/"/g, '""')}"`;
  };

  const newData = rulesLogs?.logs?.map((log) => {
    const commonFields = {
      RuleId: `"${log.ruleId}"`,
      Status: `"${log.status}"`,
      CreatedAt: `"${log.createdAt}"`,
    };
    const additionalFields = () => {
      if (rule_action === "Pause ad") {
        const adId =
          JSON.parse(log.ruleConfig)["ad_id"] !== ""
            ? escapeDoubleQuotes(JSON.parse(log.ruleConfig)["ad_id"])
            : "None";
        const ad =
          JSON.parse(log.ruleConfig)["ad"] !== ""
            ? escapeDoubleQuotes(JSON.parse(log.ruleConfig)["ad"])
            : "None";

        return {
          Ad_id: adId,
          Ad: ad,
        };
      }
      if (rule_action === "Block publisher") {
        return {
          Publishers: `"${JSON.parse(log.ruleConfig)["publishers"]}"`,
        };
      }
      if (rule_action === "Block section") {
        return {
          Sections: `"${JSON.parse(log.ruleConfig)["sections"]}"`,
        };
      }
      if (rule_action === "Change section bid modifier") {
        const oldValues = JSON.parse(log.ruleConfig)["old_values"];
        const newValues = JSON.parse(log.ruleConfig)["new_values"];

        const formatValues = (values: ChangeSectionBidModifier[]) => {
          if (!Array.isArray(values)) {
            return "[]";
          }

          return values
            .map((item) => {
              return `${item.sectionId}: ${item.cpc_modification}`;
            })
            .join(", ");
        };

        const formattedOldValues = !Array.isArray(oldValues)
          ? formatValues(oldValues)
          : `"${formatValues(oldValues)}"`;
        const formattedNewValues = !Array.isArray(newValues)
          ? formatValues(newValues)
          : `"${formatValues(newValues)}"`;
        return {
          OldValues: formattedOldValues,
          NewValues: formattedNewValues,
        };
      }
      if (rule_action === "Change publisher bid modifier") {
        const oldValues = JSON.parse(log.ruleConfig)["old_values"];
        const newValues = JSON.parse(log.ruleConfig)["new_values"];

        const formatValues = (values: ChangePublisherBidModifier[]) => {
          if (!Array.isArray(values)) {
            return "[]";
          }

          return values
            .map((item) => {
              return `${item.target}: ${item.cpc_modification}`;
            })
            .join(", ");
        };

        const formattedOldValues = !Array.isArray(oldValues)
          ? formatValues(oldValues)
          : `"${formatValues(oldValues)}"`;
        const formattedNewValues = !Array.isArray(newValues)
          ? formatValues(newValues)
          : `"${formatValues(newValues)}"`;
        return {
          OldValues: formattedOldValues,
          NewValues: formattedNewValues,
        };
      }
      if (rule_action === "Change campaign bid") {
        const oldValues = JSON.parse(log.ruleConfig)["old_values"];
        const newValues = JSON.parse(log.ruleConfig)["new_values"];

        const formatValues = (values: ChangeCampaignBid[]) => {
          if (!Array.isArray(values)) {
            return "[]";
          }

          return values
            .map((item) => {
              return `${item.cpc}`;
            })
            .join(", ");
        };
        return {
          OldValues: `"${formatValues(oldValues)}"`,
          NewValues: `"${formatValues(newValues)}"`,
        };
      }
      if (rule_action === "Change campaign budget") {
        const oldValues = JSON.parse(log.ruleConfig)["old_values"];
        const newValues = JSON.parse(log.ruleConfig)["new_values"];

        const formatValues = (values: ChangeCampaignBudget[]) => {
          if (!Array.isArray(values)) {
            return "[]";
          }

          return values
            .map((item) => {
              return `${item.daily_budget}`;
            })
            .join(", ");
        };
        return {
          OldValues: `"${formatValues(oldValues)}"`,
          NewValues: `"${formatValues(newValues)}"`,
        };
      }
      if (rule_action === "Change ad bid modifier") {
        const oldValues = JSON.parse(log.ruleConfig)["old_values"];
        const newValues = JSON.parse(log.ruleConfig)["new_values"];

        const formatValues = (values: ChangeAdBidModifier[]) => {
          if (!Array.isArray(values)) {
            return "[]";
          }

          return values
            .map((item) => {
              return `${item.id}: ${item.cpcAdjustment}`;
            })
            .join(", ");
        };

        const formattedOldValues = !Array.isArray(oldValues)
          ? formatValues(oldValues)
          : `"${formatValues(oldValues)}"`;
        const formattedNewValues = !Array.isArray(newValues)
          ? formatValues(newValues)
          : `"${formatValues(newValues)}"`;
        return {
          OldValues: formattedOldValues,
          NewValues: formattedNewValues,
        };
      }
      if (rule_action === "Best Ads per account") {
        const cvrAds = JSON.parse(log.ruleConfig)["cvr_ads"];
        const ctrAds = JSON.parse(log.ruleConfig)["ctr_ads"];
        const formatValues = (values: string[]) => {
          if (!Array.isArray(values)) {
            return "[]";
          }
          return values
            .map((item) => {
              return `${item}`;
            })
            .join(", ");
        };
        const formattedCvrAds = !Array.isArray(cvrAds)
          ? formatValues(cvrAds)
          : `"${formatValues(cvrAds)}"`;
        const formattedCtrAds = !Array.isArray(ctrAds)
          ? formatValues(ctrAds)
          : `"${formatValues(ctrAds)}"`;
        return {
          CvrAds: formattedCvrAds,
          CtrAds: formattedCtrAds,
        };
      }
    };

    const handleErrorMessage = () => {
      const errorMessage = log.errorMessage;
      if (!errorMessage) {
        return;
      } else {
        const parsedObj = JSON.parse(errorMessage);
        if (parsedObj && "validationErrors" in parsedObj) {
          return parsedObj["validationErrors"];
        }
        if (parsedObj && "message" in parsedObj && "http_status" in parsedObj) {
          return parsedObj["message"];
        }
        if (
          "message" in parsedObj &&
          !("validationErrors" in parsedObj) &&
          !("http_status" in parsedObj)
        ) {
          return parsedObj["message"];
        }
      }
    };

    return {
      ...commonFields,
      ...additionalFields(),
      ...(rule_action !== "Best Ads per account"
        ? {
            CampaignId: `"${JSON.parse(log.ruleConfig)["campaign_id"]}"`,
            Campaign: `"${JSON.parse(log.ruleConfig)["campaign"]}"`,
          }
        : {}),
      Account: `"${JSON.parse(log.ruleConfig)["account"]}"`,
      ErrorMessage: `"${handleErrorMessage() ?? ""}"`,
    };
  });

  const saveDataToCSV = (parsedData: object) => {
    const csvData = handleConvertToCSV(parsedData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const ruleName = data.find((rule) => rule.id == ruleId)?.name;
    a.download = `${ruleName ? ruleName : "Rule"}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  if (newData) {
    saveDataToCSV(newData);
  }
};
