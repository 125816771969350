import React, { useEffect, useState } from "react";
import {Table, Tag, Button, Switch, Popconfirm, Tooltip, Checkbox, message} from "antd";
import moment from 'moment';
import {
    CopyOutlined,
    DeleteOutlined, PlusOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";

import {CampaignUrlItem, StatusEnum} from "../../redux/services/smartScreenApi/types";
import axiosInstance from "../../utils/Interceptor";
import {useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {smartScreenApi} from "../../redux/services/smartScreenApi";
import AssignCampaignModal from "./assignCampaignModal";

const SmartScreenTable: React.FC<{
  data: CampaignUrlItem[];
  status: string;
  refetch: () => any;
  userRulePermission?: boolean;
  isAllowToUpdate?: boolean;
}> = ({ data, status, refetch, userRulePermission, isAllowToUpdate }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [urlData, setData] = useState<CampaignUrlItem[]>([]); // Define ruleData state
  const [updateStatus] = smartScreenApi.useUpdateStatusMutation();
  const [id, setId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (id: number) => {
    setIsModalVisible(true);
    setId(id);
    console.log("ID:", id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id: number) => {
    await axiosInstance
      .delete(`campaign_urls/${id}`)
      .then((result) => {
        if (result.status === 200) {
          refetch();
        }
      })
      .catch(() => {
        error();
      });
  };

  const handleUpdateStatus = async (id: number, status: StatusEnum) => {
    await updateStatus({
      id: id,
      data: {
        status: status
      }
    });
    console.log(status);
    refetch();
  };

  const navigate = useNavigate();
  const error = () => {
    messageApi.open({
      type: "error",
      content: "Error",
    });
  };

  useEffect(() => {
    setData(data);
  }, [data]);

  const rowSelection = {
    type: 'checkbox' as const, // Specify the type as 'checkbox'
  };

  const columns: ColumnsType<CampaignUrlItem> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'Campaign Count',
    dataIndex: 'campaignIds',
    key: 'campaignIds',
    render: (campaignIds: number[]) => campaignIds?.length, // Render the length of the array
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
        return (
          <div>
            {status == 'enabled' ? (
              <Tag color="green">Enabled</Tag>
            ) : (
              <Tag color="red">Disabled</Tag>
            )}
          </div>
        );
      },
  },
  {
    title: 'Scan Status',
    dataIndex: 'scanStatus',
    key: 'scanStatus',
    render: (status: string) => {
        return (
          <div>
            {status === 'good' ? (
                <Tag color="green">Good</Tag>
            ) : status === 'issue' ? (
                <Tag color="red">Issue</Tag>
            ) : null }
          </div>
        );
      },
  },
  {
    title: 'Last Check Date',
    dataIndex: 'lastCheckDate',
    key: 'lastCheckDate',
    render: (
        lastCheckDate: string) => lastCheckDate ? moment(lastCheckDate).format('YYYY-MM-DD HH:mm:ss'
    ) : null,
  },
  {
      title: "",
      dataIndex: "id",
      key: "action",
      render: (id: number, field: CampaignUrlItem) => (
        <div style={{ display: "flex", alignItems: "center" }}>
            <span>
                <Switch
                  disabled={!userRulePermission}
                  onChange={() => userRulePermission && handleUpdateStatus(id, field.status === "enabled" ? 'disabled' : 'enabled')}
                  size="small"
                  checked={field.status === "enabled"}
                />
            </span>

            <Popconfirm
              disabled={!isAllowToUpdate}
              title="Delete the Url"
              description="Are you sure to delete this Url?"
              icon={
                isAllowToUpdate ? (
                  <QuestionCircleOutlined style={{ color: "red" }} />
                ) : (
                  <QuestionCircleOutlined style={{ color: "gray" }} />
                )
              }
              onConfirm={() => handleDelete(id)}
            >
              <span style={{ marginLeft: "5%", marginRight: "5%", cursor: "pointer" }}>
                {isAllowToUpdate ? (
                  <DeleteOutlined style={{ fontSize: "20px", color: "rgba(255, 0, 0, 0.8)" }}/>
                ) : (
                  <DeleteOutlined
                    style={{
                      fontSize: "20px",
                      color: "gray",
                      cursor: "not-allowed",
                    }}
                  />
                )}
              </span>
            </Popconfirm>
            <Button
              onClick={() => showModal(id)}
              icon={<PlusOutlined />}
            >
              Assign Campaign
            </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* JSX for any message components */}
      <AssignCampaignModal id={id} refetch={refetch} visible={isModalVisible} onCancel={handleCancel} />
      <Table
        columns={columns}
        dataSource={urlData?.map((item) => ({ ...item, key: item.id }))}
        loading={status !== "fulfilled"}
      />
    </>
  );
};

export default SmartScreenTable;
