import { Button, Col, Dropdown, MenuProps, message, Row, Space, Modal } from "antd";
import SmartScreenTable from "./smartScreenTable";
import {
  BarChartOutlined,
  CaretRightOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DollarOutlined,
  DollarTwoTone,
  DownOutlined,
  MoneyCollectOutlined,
  PauseOutlined,
  PlusOutlined,
  SyncOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
  TrademarkCircleOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { smartScreenApi } from "../../redux/services/smartScreenApi";
import React, { useContext, useEffect, useState } from "react";
import {CREATE_PERMISSION, RULE_PERMISSION, UPDATE_PERMISSION} from "../../utils";
import { WebsocketContext } from "../../shared/context/websocketContext";

import useAuth from "../../hooks/useAuth";
import {CampaignUrlItem} from "../../redux/services/smartScreenApi/types";
import {campaignsApi} from "../../redux/services/campaignsApi";
import AddUrlModal from "./addUrlModal";

const SmartScreen = () => {
  const navigate = useNavigate();
  const { selectedRule } = useAppSelector((item) => item.rules);
  const { data: user } = campaignsApi.useGetUserQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const { data, status, refetch } = smartScreenApi.useFetchUrlsQuery("");
  const websocket = useContext(WebsocketContext);

  useEffect(() => {
    refetch();
  }, []);

  const [isAllowToUpdate, setIsAllowToUpdate] = useState<boolean>(
    !!user?.role.permission?.find(
      (permission) => permission.name === UPDATE_PERMISSION,
    )?.name,
  );

  const [isAllowToCreate, setIsAllowToCreate] = useState<boolean>(
    !!user?.role.permission?.find(
      (permission) => permission.name === CREATE_PERMISSION,
    )?.name,
  );

  useEffect(() => {
    setIsAllowToCreate(
      !!user?.role.permission?.find(
        (permission) => permission.name === CREATE_PERMISSION,
      )?.name,
    );
    setIsAllowToUpdate(
      !!user?.role.permission?.find(
        (permission) => permission.name === UPDATE_PERMISSION,
      )?.name,
    );
  }, [user]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Space size="middle" style={{ marginBottom: "1%" }}>
            <Button
              disabled={!isAllowToCreate}
              onClick={showModal}
              icon={<PlusOutlined />}
            >
              Add Url
            </Button>
        </Space>
        <AddUrlModal refetch={refetch} visible={isModalVisible} onCancel={handleCancel} />

        <SmartScreenTable
              data={data ? data : []}
              status="fulfilled"
              refetch={refetch}
              userRulePermission={true}
              isAllowToUpdate={true}
            />
      </div>
    </div>
  );
};

export default SmartScreen;
